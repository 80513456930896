import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Button } from 'reactstrap';
import { DISASSOCIATE_BY_POD_ID } from '../GraphQL/Mutations';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useDisassociatePod } from '../Hooks/useDisassociatePod';
import useGraphQL from '../GraphQL/GraphQL';
import PodInfo from '../Pages/ScanPodId/podInfo';
import { GET_CURRENT_POD, GET_POD_INFO } from '../GraphQL/Queries';
import {
    COMMAND_RESPONSE_UUID,
    COMMAND_UUID,
    INFORMATION_UUID,
    SKIIN_SERVICE_UUID
} from "../Utlis/constant";
import { useTranslation } from 'react-i18next';


function PopupMenu(props) {

    // let state1, state2, state3 = false, state4 = false, state5 = false;
    const [currentState, setCurrentState] = useState("state1");
    const process = require('../../src/Assests/clear_data_processing_icon.png');
    const success = require('../../src/Assests/successIcon.png');
    const unsuccesful = require('../../src/Assests/crossIcon.png');
    const { disassociatePodByPodId } = useDisassociatePod();
    const groupID = localStorage.getItem('clinicId');
    const podID = localStorage.getItem("podId");
    const [result, setResult] = useState('');
    const { makeAPIMutationWithOptions } = useGraphQL();
    const [isPodUnlocked, setPodUnlocked] = useState(false)
    const { makeAPIQueryWithOptions } = useGraphQL();
    const serialNo = podID.slice(1, 12)
    const serialNoToString = serialNo.toString()
    const { t, i18n } = useTranslation();


    useEffect(() => {
        console.log(currentState)
    }, [])

    const fetchDissociateInfo = async () => {
        try {
            const response = await makeAPIMutationWithOptions('disassociateByPodId', DISASSOCIATE_BY_POD_ID, {
                groupId: groupID, podId: podID.slice(1, 12)
            });

            const result = response?.data
            console.log("disassociateByPodId result:", result)
            setResult(result)
            if (result) {
                if (result.disassociatePodByPodId.message === "Successfully disassociated the pod") {
                    console.log("disassociated success")
                    setCurrentState("state3")
                }
                else {
                    console.log("disassociated error: ", result.disassociatePodByPodId.message)
                    setCurrentState("state4")
                }
            } else {
                console.log("API call failed error: ", result)
                setCurrentState("state4")
            }

        } catch (ex) {
            console.log('getDisassociatedPod Summary', ex);
            setCurrentState("state4")
        }
    };

    // const onPressDisassociate = () => {

    //     console.log('Connecting to Pod');

    //     // API call for getting Pod Unlock key
    //     const getPodInfo = async (serialNoToString, iv, secret) => {
    //         const fetchPolicy = 'network-only'
    //         const response = makeAPIQueryWithOptions('getPodInfo ', GET_POD_INFO,
    //             {
    //                 podId: serialNoToString,
    //                 IV: iv,
    //                 secretNumber: secret
    //             },
    //             fetchPolicy);
    //         return response
    //     }

    //     let unlockCommand, responseArray;

    //     // Helper function
    //     function delay(ms) {
    //         return new Promise(resolve => setTimeout(resolve, ms));
    //     }

    //     // Receive notifications from Command Response. 
    //     // Will get secret and IV from this response. 
    //     // Secret and IV get sent as inputs to getPodInfo API call
    //     function handleCharacteristicValueChanged(event) {
    //         const value = event.target.value;
    //         responseArray = new Uint8Array(value.buffer);
    //         console.log('Received notification:', responseArray);
    //         let unlockRequest = Array.from(responseArray.slice(0, 3));

    //         // Helper function
    //         function arraysEqual(a, b) {
    //             if (a.length !== b.length) return false;
    //             for (let i = 0; i < a.length; i++) {
    //                 if (a[i] !== b[i]) return false;
    //             }
    //             return true;
    //         }

    //         // If unlockRequest is [148, 237, 0], then Pod is unlocked
    //         if (arraysEqual(unlockRequest, [148, 237, 0])) {
    //             setPodUnlocked(true)
    //             console.log('Pod is Unlocked = ', isPodUnlocked);
    //         }

    //         if (arraysEqual(unlockRequest, [4, 153, 0])) {
    //             console.log('Unlocking Pod with secret and IV');
    //             const secret1 = responseArray.subarray(3, 11);
    //             const numericSecret = Array.from(secret1);
    //             const iv = responseArray.subarray(11, 27);
    //             const numericIV = Array.from(iv);

    //             // Secret and IV get sent as inputs to getPodInfo API call
    //             getPodInfo(serialNoToString, numericIV, numericSecret).then((response) => {

    //                 if (response?.data?.getPodInfo) {
    //                     const resultSecret = response.data.getPodInfo.encryptedSecretNumber;
    //                     const resultIV = response.data.getPodInfo.IV;


    //                     // Use outputs from getPodInfo API call to build the unlockCommand key
    //                     const UNLOCK_POD_OPCODE = [148, 237];
    //                     unlockCommand = new Uint8Array(UNLOCK_POD_OPCODE.concat(resultSecret, resultIV));
    //                     console.log('unlockCommand = ', unlockCommand)
    //                 } else {
    //                     console.log(response)
    //                     console.log("clear data error")
    //                     setCurrentState("state5");
    //                 }
    //             })
    //         }
    //     }

    //     // Check if unlockCommand is populated
    //     function waitForUnlockCommand() {
    //         return new Promise(resolve => {
    //             const checkInterval = setInterval(() => {
    //                 if (unlockCommand) {
    //                     clearInterval(checkInterval);
    //                     resolve();
    //                 }
    //             }, 100); // Check every 100ms
    //         });
    //     }

    //     let device, server, service, writeCharacteristic, responseCharacteristic;

    //     // Scan for BlueTooth Devices containing the Skiin Service UUID
    //     navigator.bluetooth.requestDevice({
    //         filters: [{ services: [SKIIN_SERVICE_UUID] }],
    //         optionalServices: [COMMAND_UUID, COMMAND_RESPONSE_UUID, INFORMATION_UUID]
    //     })
    //         // Connect to selected Device.
    //         .then(selectedDevice => {
    //             device = selectedDevice;
    //             return device.gatt.connect();
    //         })
    //         .then(connectedServer => {
    //             server = connectedServer;
    //             return server.getPrimaryService(SKIIN_SERVICE_UUID);
    //         })
    //         .then(service => {
    //             // Get the characteristic for notifications
    //             return service.getCharacteristic(COMMAND_RESPONSE_UUID);
    //         })
    //         .then(characteristic => {
    //             // Add an event listener to handle notifications
    //             characteristic.addEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
    //             return characteristic.startNotifications();
    //         })
    //         .then(characteristic => {
    //             console.log(`Subscribed to notifications for ${characteristic.uuid}`);
    //             // Now, get the characteristic for writing
    //             writeCharacteristic = characteristic.service.getCharacteristic(COMMAND_UUID);
    //             return writeCharacteristic
    //         })
    //         .then(writeCharacteristic => {
    //             // Get Secret and IV 
    //             const getSecret = new Uint8Array([4, 153])
    //             console.log('getSecret = ', getSecret)
    //             writeCharacteristic.writeValue(getSecret)
    //             return writeCharacteristic
    //         })
    //         .then(async writeCharacteristic => {
    //             try {
    //                 const eraseMetrics = new Uint8Array([2, 144, 0]) // Erase stored data metrics
    //                 const eraseECG = new Uint8Array([2, 144, 1]) // Erase stored data ECG
    //                 await waitForUnlockCommand();
    //                 console.log('writing unlockCommand = ', unlockCommand);
    //                 await writeCharacteristic.writeValue(unlockCommand);
    //                 await delay(1000);
    //                 console.log('writing Erase Command metrics = ', eraseMetrics);
    //                 await writeCharacteristic.writeValue(eraseMetrics);
    //                 console.log('writing Erase Command ecg = ', eraseECG);
    //                 await writeCharacteristic.writeValue(eraseECG);
    //                 await server.disconnect();
    //                 console.log('Disconnecting BlueTooth Device');
    //                 await setCurrentState("state3");
    //                 await fetchDissociateInfo();
    //                 console.log("dis api")
    //             } catch (error) {
    //                 console.error('Error during GATT operations:', error);
    //                 setCurrentState("state2")
    //             } finally {
    //                 // await setCurrentState("state3"); 
    //                 // await fetchDissociateInfo();
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //             setCurrentState("state5")
    //         });
    // }

    // const fetchPodInfo = async () => {

    //     try {
    //         const response = await makeAPIQueryWithOptions('getCurrentPod', GET_CURRENT_POD, {
    //             groupId: groupID, podId: podID.slice(1, 12)
    //         });
    //         const result = response?.data
    //         return setResult(result)

    //     } catch (ex) {
    //         console.log('getCurrentPod Summary', ex);
    //     }
    // };


    return (
        <>
            {(currentState === "state1") && (
                <>
                    <div className="header"> {t("unlikePodConfirmationTitle")} </div>
                    <div className="content">
                        {t("unlikePodConfirmationText")}
                    </div>
                    <div className="actions">
                        <Button
                            onClick={() => {
                                setCurrentState("state2")
                                fetchDissociateInfo()
                                // onPressDisassociate();
                            }
                            }
                            type="button"
                            color="primary"
                            className="px-4 clear-btn-popup"

                        >
                           {t("unlinkBtnText")}
                        </Button>
                        <Button
                            onClick={() => {
                                props.close()
                            }}
                            type="button"
                            color="primary"
                            className="px-4 go-back-btn-popup "
                        >
                           {t("goBack")}
                        </Button>
                    </div>

                </>

            )
            }

            {(currentState === "state2") && (

                <><div className="header">{t("inProgressTitle")} </div>
                    <div className="process-content">
                        <div className='process-content-text'>
                            <img alt="processing" className="process-img" src={process} />
                            <span className='process-text'>{t("inProgressText")}</span>
                        </div>
                    </div>
                </>
            )}


            {(currentState === "state3") && (

                <><div className="header">{t("podUnlinkedTitle")} </div>
                    <div className="process-content">
                        <div className='process-content-text'>
                            <img alt="processing" className="process-img" src={success} />
                            <span className='process-text'> {t("podUnlinkedMessage")} </span>
                        </div>
                        <Button
                            //  onClick={() => {
                            //     props.close()
                            // }}
                            onClick={() => {
                                props.close(true);
                            }}
                            type="button"
                            color="primary"
                            className="px-4 finish-btn"
                        >
                            {t("finishBtnText")}
                        </Button>
                    </div>
                </>
            )
            }

            {(currentState === "state4") && (

                <><div className="header">{t("unsuccesfulMessageTitle")} </div>
                    <div className="process-content">
                        <div className='process-content-text'>
                            <img alt="processing" className="process-img" src={unsuccesful} />
                            <span className='process-text'> {t("unsuccesfulMessageText")} </span>
                            <div className='try-again-text'>{t("unsuccesfulTryAgainText")}</div>
                        </div>

                        <div className="actions">
                            <Button
                                onClick={() => {
                                    console.log('Unsuccessful API Disassociate Try again');
                                    setCurrentState("state2");
                                    fetchDissociateInfo()
                                }}
                                type="button"
                                color="primary"
                                className="px-4 clear-btn-popup"

                            >
                                {t("tryAgainBtnText")}
                            </Button>
                            <Button
                                onClick={() => {
                                    props.close()
                                }}
                                type="button"
                                color="primary"
                                className="px-4 go-back-btn-popup "
                            >
                                 {t("close")}
                            </Button>
                        </div>
                    </div>
                </>
            )
            }

            {/* {(currentState === "state3") && (

                <><div className="header">In progress... </div>
                    <div className="process-content">
                        <div className='process-content-text'>
                            <img alt="processing" className="process-img" src={process} />
                            <span className='process-text'> Disassociated Pod </span>
                        </div>
                    </div>
                </>
            )
            } */}

            {/* {(currentState === "state5") && (

                <>
                    <div className="header">Unsucessful </div>
                    <div className="process-content">
                        <div>
                            <div className='process-content-text' style={{ paddingLeft: 10, display: "flex" }}>
                                <img alt="processing" className="process-img" src={unsuccesful} />
                                <div className='process-text' >Unable to clear health data on the Pod</div>
                            </div>
                            <div className='unsuccesful-content-text' style={{ color: "#003D78", fontSize: 12 }} >Please ensure:
                                <ul className='unsuccesful-ul'>
                                    <li >Pod ID you entered is correct and keep this Pod close to your device</li>
                                    <li>Bluetooth on your device is turned on </li>
                                    <li>Pod has battery charge; if there is no charge left, its LED light will not be lit </li>
                                </ul>
                                <div style={{ color: "#003D78", fontSize: "12px" }}>If issues persist, please contact your account representative for support.</div>
                            </div>
                        </div>
                        <div className='process-content-text' style={{ paddingLeft: 10, display: "flex" }}>
                            <img alt="processing" className="process-img" src={process} />
                            <div className='process-text'>Disassociated Pod</div>
                        </div>
                        <div className="actions">
                            <Button
                                onClick={() => {setCurrentState("state2")
                                                onPressDisassociate();}}
                                type="button"
                                color="primary"
                                className="px-4 clear-btn-popup"

                            >
                                Try Again
                            </Button>
                            <Button
                                onClick={() => {
                                    props.close()
                                }}
                                type="button"
                                color="primary"
                                className="px-4 go-back-btn-popup "
                            >
                                Close
                            </Button>
                        </div>
                    </div>

                </>
            )
            } */}

        </>

    );

}
export default PopupMenu;
