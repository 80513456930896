const environment = {
    dev: {
        awsconfig: {
            aws_project_region: 'us-east-1',
            // aws_cognito_identity_pool_id: 'us-east-1:793672161147:userpool/us-east-1_bHvuIv5IZ',
            aws_cognito_region: 'us-east-1',
            aws_user_pools_id: 'us-east-1_bHvuIv5IZ',
            aws_user_pools_web_client_id: '5gesh7lup6jopc33qfq00up8pi',
            oauth: {},
        },
        nodeAPI: 'https://api-pat.dev.skiinserver.com/',
        // nodeAPI: 'http://18.215.189.27:4100',
        webSocketLink: 'wss://lxwkunc4vj.execute-api.us-east-1.amazonaws.com/dev',
        enablePendo: true,
    },
    prod: {
        awsconfig: {
            aws_project_region: 'ca-central-1',
            aws_cognito_region: 'ca-central-1',
            aws_user_pools_id: 'ca-central-1_72AvhBx7V',
            aws_user_pools_web_client_id: '314ca2pa0o0f7lk5a3nkoga45j',
            oauth: {},
        },
        nodeAPI: 'https://api-pat.skiinserver.com/',
        // nodeAPI: 'http://18.215.189.27:4100',
        webSocketLink: 'wss://4n09cmmy33.execute-api.ca-central-1.amazonaws.com/prod/',
        enablePendo: true,
    }
};

export const config = environment['prod']

export const MVCPWebsite = "https://scwp.skiinserver.com/login"

export const browserName = "Chrome"

export const browserVersion = ["115.0.0.0", "116.0.0.0", "117.0.0.0", "118.0.0.0"]

export const OSName = "Android"

export const timeoutValue = "1800000"

export const HIGH_BYTE_FACTOR = 4294967296;

export const SKIIN_SERVICE_UUID = "20046800-9630-4ea8-9d21-04148452e81f"
export const INFORMATION_UUID = "20046801-9630-4ea8-9d21-04148452e81f"
export const STORED_METRICS_UUID = "20046890-9630-4ea8-9d21-04148452e81f"
export const COMMAND_UUID = "20046802-9630-4ea8-9d21-04148452e81f";
export const COMMAND_RESPONSE_UUID = "20046803-9630-4ea8-9d21-04148452e81f";
