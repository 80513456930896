import React, {useEffect, useState} from 'react';
import './selectClinic.scss';
import { AWS } from '../../Services/AuthService';
import { Input } from "reactstrap";
import { useTranslation } from 'react-i18next';


// import { AWS } from '../../Services/AuthService';
//import { Link } from 'react-router-dom';
// import ClipLoader from 'react-spinners/ClipLoader';
// import { toast, } from 'react-toastify';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'
// import { formatErrorMessage } from '../../Utils/Helpers';
// import 'react-toastify/dist/ReactToastify.css';

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row,
  Form,
  } from 'reactstrap';
import { useNavigate } from 'react-router-dom';


  const logo = require('../../Assests/skinnLogo.png');

function SelectClinic(){

  const aws = AWS.getInstance();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

    useEffect(() => {

      if(aws.groupId || localStorage.getItem("clinicId")){
        navigate("/scan-pod-id")
      }
    }, [])
    const [selectedClinic, setselectClinic] = useState("");
   // const [getGroups, setgetGroups] = useState([]);
    

    const radioChangeHandler = (e) => {
      console.log("selected clinic value: ", e)
        setselectClinic(e.groupId);
        localStorage.setItem("clinicName", e.groupName)
    };

    const formSubmit = (e) => {
      if(selectedClinic){
        aws.groupId = selectedClinic;
        localStorage.setItem("clinicId", selectedClinic);
        navigate("/scan-pod-id");
      }  
    };

const RadioButton = (props) => {
        const { onChange, selectedValue, label, value } = props;
        return (
          
          <div className="RadioButton">
            <Input 
              type="radio" 
              name="clinic-radio"  
              className='radio-btn-input clinic-radio'
              onChange={onChange}
              value={value}
              checked={selectedValue === value}/> 
           
            <label className='radio-btn-txt'>{label}</label>
          </div>
        );
      };
      

    const selectClinicForm = (
        <>
        <Form className="form-horizontal" onSubmit={formSubmit}>
         <p className="select-clinic-text">{t("selectClinicText")}</p>
         <div className="radio-btn-container" style={{ display: "flex", flexDirection: "column", paddingLeft: '25px', alignItems: 'start' }}>
            <div className="radio-btn">
              {aws.getGroups?.map(getGroup => (
                <label className="radio-btn-txt" >
                <RadioButton
                  key={getGroup.groupId}
                  color="primary"
                    onChange={() => {radioChangeHandler(getGroup)}}
                    selectedValue={selectedClinic}
                    value={getGroup.groupId}
                // required
                /> 
                {getGroup.groupName}
                </label>
        ))}
    </div>
      </div>
      <div className='continue-btn'>
      <Button
              onClick={formSubmit}
              type="button"
              color="primary"
              className="px-4 login-text"
            >
            {t("continueBtnText")}
            </Button>
            </div>
            </Form>
        </>
    );

    return (
        // eslint-disable-next-line react/jsx-no-comment-textnodes
        <div className="app flex-row align-items-center">
          <Container className='form-container'>
            <Row className="container-row">
              <Col md="5" className='container-width'>
                <CardGroup>
                  <Card className="container-card">
                    <Row className='img-row'>
                      <img alt="SKIIN" className="skinn-img" src={logo} />
                    </Row>
                    <p className='logo-text'>Pod Admin Tool</p>
                    <CardBody className='form'>
                      {selectClinicForm}
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      );
}

export default SelectClinic;