import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {

        translation: {

            //Common
            podID: "Pod ID",
            back: "Back",
            logOut: "Log out",
            close: "Close",

            //login page
            PAT: "Pod Admin Tool",
            loginText: "Log in using your MVCP credentials.",
            loginBtnText: "Login",
            forgetPasswordText1: "Forgot your password? Reset it on the",
            MVCP: "MVCP website",
            forgetPasswordText2: "\nfirst, then log back in here.",
            forgetPasswordText3: "If you continue to experience issues, please get in touch with your account representative.",
            skiinPATVer: "Skiin Pod Admin Tool Ver.",
            skiinAPIVer: "API ver.",
            //Error message
            emailErrorText: "Please enter your email address",
            passwordErrorText: "Please enter your password",
            invalidCredErrorText: "Invalid credentials. Please try again",
            inactivityLogOutText: "You were logged out due to 30 minutes of inactivity. Please log in again to continue using the tool.",

            //Banner
            bannerContent: "This tool is tested for Chrome 115, 116, 117, and 118 under Android OS. You are using an unsupported browser, and some functionalities may not work as expected.",

            //Select Clinic Page
            selectClinicText: "Which clinic would you like to log into?",
            continueBtnText: "Continue",

            //Scan Pod Page
            scanPodHeading: "How to use the tool",
            scanPodListItem1: "Either scan the QR code on Pod or enter it manually",
            scanPodListItem2: "Check if a Pod is linked to a patient at your clinic",
            scanPodListItem3: "Unlink the Pod from a patient",
            scanPodListItem4: "View the history of a Pod with your clinic",
            scanPodOption1: "Option 1: Scan QR code on back of Pod",
            scanPodOption2: "Option 2: Enter ID found on back of Pod",
            scanQRBtnText: "Scan QR Code",
            checkBtnText: "Check",

            //QR Code Scanner page
            cameraPermissionErrorTitle: "Allow camera permission to scan QR Code.",
            cameraPermissionErrorLine1: "In order to scan the QR Code, please allow camera permission for Google Chrome on your device. Once you have allowed permission, please go back and try again.",
            cameraPermissionErrorLine2: "You may also go back and use option 2 to enter the Pod ID manually.",
            goBack: "Go Back",

            //Pod Info Page
            linkedWith: "Linked with:",
            linked: "Linked",
            unlinkPodBtnText: "Unlink Pod from the patient",
            viewHistoryBtnText: "View Pod history with your clinic",
            notLinkedWithClinicText: "This Pod is linked with a patient who is not a part of your clinic. Please contact your account representative for support.",
            notLinkedWithPatientText: "This Pod is currently not linked to a patient. You may use the Skiin Connected Life application to link it to a patient.",

            //Pod History Page
            historyTitle: "- History with your clinic",
            unlinked: "Unlinked:",
            linkedWith: "Linked with:",
            unlikedBy: "Unlinked by:",
            noHistoryText: " No history to show.",

            //Unlink Pod Flow
            unlikePodConfirmationTitle: "Are you sure?",
            unlikePodConfirmationText: "Pod will be unlinked from the patient",
            unlinkBtnText: "Unlink",
            inProgressTitle: "In progress...",
            inProgressText: "Unlinked Pod from the patient",
            podUnlinkedTitle: "Pod unlinked",
            podUnlinkedMessage: "Unlinked Pod from the patient",
            finishBtnText: "Finish",
            unsuccesfulMessageTitle: "Unsucessful",
            unsuccesfulMessageText: "Unable to unlink Pod",
            unsuccesfulTryAgainText: "Please try again. If issues persist, please contact your account representative for support.",
            tryAgainBtnText: "Try again",
        }
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
