import useGraphQL from "../GraphQL/GraphQL";
import { GET_USER_GROUP } from "../GraphQL/Queries";
import {useState} from "react";

export const useGetUserGroups = () => {
    const { makeAPIQueryWithOptions } = useGraphQL();
    const [userGroups, setUserGroups] = useState([]);

    const getUserGroups = async () => {
        try {
            const response = await makeAPIQueryWithOptions('getUserGroups ', GET_USER_GROUP, );
            const result = response?.data?.getUserGroups ?? [];
            setUserGroups(result);
            return result;
        } catch (ex) {
            console.log('getUserGroups catch error: ', ex);
            return [];
        }
    };
    return {
        userGroups,
        getUserGroups
    }
}
