import useGraphQL from "../GraphQL/GraphQL";
import {GET_CURRENT_POD, GET_USER_GROUP} from "../GraphQL/Queries";
import {useState} from "react";

export const useGetCurrentPod = () => {
    const { makeAPIQueryWithOptions } = useGraphQL();
    const [currentPod, setCurrentPod] = useState({});

    const getCurrentPod = async (params) => {
        try {
            const response = await makeAPIQueryWithOptions('getCurrentPod ', GET_CURRENT_POD, {
                groupId: params.groupId,
                podId: params.podId
            });
            const result = response?.data?.getCurrentPod ?? {};
            setCurrentPod(result);
            return result;
        } catch (ex) {
            console.log('getCurrentPod catch error: ', ex);
            return {};
        }
    };
    return {
        currentPod,
        getCurrentPod
    }
}