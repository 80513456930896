import { onError } from '@apollo/client/link/error';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { config, timeoutValue } from '../Utlis/constant';
import { BehaviorSubject } from 'rxjs';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import {
    GET_USER_GROUP
  } from '../GraphQL/Queries';
import useGraphQL from "../GraphQL/GraphQL";


class AuthService {
  

    constructor() {
        this.user = undefined;
        this.httpLink = createHttpLink({
            uri: config.nodeAPI,
          });
        this.authLink = this.getAuthLink();
        this.setClient();
        this.isAuthenticated = new BehaviorSubject(null);
        const sessionExpired = localStorage.getItem('session-expired');
        const userUnauthorized = localStorage.getItem('user-unauthorized');
        if (sessionExpired) {
          localStorage.removeItem('session-expired');
          toast.error('The session has expired.', this.toastConfig);
        } else if (userUnauthorized) {
          localStorage.removeItem('user-unauthorized');
         toast.error('User is not authorized.', this.toastConfig);
        }
    }


    async logout(reason = 'user-log-out') {
      try {
        await Auth.signOut();
        this.user = undefined;
        this.authLink = this.getAuthLink();
        this.setClient();
        this.isAuthenticated.next(false);
        this.role = '';
        this.permissions = undefined;
        this.getGroups = [];
        this.groupId = undefined;
        localStorage.removeItem('podId')
        localStorage.removeItem('clinicId')
        localStorage.removeItem("close")
        // console.log("timeout")
        window.location.replace('/');
        if(reason === 'session-timeout'){
          localStorage.setItem('session-timeout', '1')
        }else if(reason === 'invalid-role'){
          localStorage.setItem('invalid-role', '1')
        }
      }catch (error) {
        console.error(error, 'error signing out: ');
      }
      return false;
    }

  getAuthLink() {
    return setContext((_, { headers }) => {
      const token = this.user?.signInUserSession?.accessToken?.jwtToken;
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    });
  }

  login(email, password) {
    return new Promise((resolve, reject) => {
      Auth.signIn({
        username: email.trimEnd(),
        password,
      })
        .then(resp => {
          if (resp) {
            const accessToken = resp.signInUserSession.accessToken.jwtToken;
            const refreshToken = resp.signInUserSession.refreshToken.token;
            const {
              accessTokenExpireTimestamp,
              refreshTokenExpireTimestamp,
              sub: id,
            } = resp.signInUserSession.idToken.payload;

            Auth.currentAuthenticatedUser()
              .then(async resp2 => {
                this.user = resp2;
                this.userName = this.getName();
                this.authLink = this.getAuthLink();
                this.setClient();
                await this.fetchGroupInfo();


                return resolve({
                  accessToken,
                  refreshToken,
                  accessTokenExpireTimestamp,
                  refreshTokenExpireTimestamp,
                  id,
                });
              })
              .catch(err => {
                reject(err);
              });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  fetchGroupInfo = async () => {

      try {
        this.isTimeout();
        const response = await this.client.query({
          query: GET_USER_GROUP,
          fetchPolicy: 'no-cache',
        });
         this.getGroups = response?.data?.getUserGroups

        if (response?.data?.getUserGroups) {
          if(response?.data?.getUserGroups.length === 0){
              this.logout("invalid-role");
          }
            if(response?.data?.getUserGroups.length === 1){
             this.groupId = response?.data?.getUserGroups[0].groupId;
             localStorage.setItem("clinicId", this.groupId);
              console.log("getUserGroups groupId: ",this.groupId);
            }

        }
      } catch (er) {
        //this.groupCreatedAt = undefined;
      }
  };

  getName = () => {
    if (this.user) {
      return `${this.user.signInUserSession?.idToken?.payload['custom:firstName']} ${this.user.signInUserSession?.idToken?.payload['custom:lastName']}`;
    }
    return '';
  };

  async checkUserAuthenticated() {
    try {
      this.user = await Auth.currentAuthenticatedUser();
      await this.fetchGroupInfo();
    } catch (err) {
      this.user = undefined;
    }

    if (this.user) {
      this.userName = this.getName();
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  isAuthenticated() {
    return !!this.user;
  }

  isTimeout(){
    //console.log("inside timeout function")
    if(this.timer){
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() =>  {
      this.logout('session-timeout')
    }, timeoutValue);

  
  }

  // sessionExpired = message => {
  //   if (this.timer) clearTimeout(this.timer);
  //   this.timer = setTimeout(() => {
  //     console.log('Logging out beacause of', message);
  //     console.log("Permission Denied")
  //     this.logout(message);
  //    // window.location.reload();
  //   }, 10000);
  // };


  setClient() {
    const logoutLink = onError(({ networkError }) => {
      if (networkError && networkError.statusCode === 401) {
        //this.sessionExpired('sessionExpired');
        this.logout("invalid-role");
      }
    });
    const links = logoutLink.concat(this.authLink.concat(this.httpLink));
    this.client = new ApolloClient({
      link: links,
      cache: new InMemoryCache(),
      credentials: 'include',
      fetchOptions: {
        mode: 'no-cors',
      },
    });
}

getClient() {
    return this.client;
  }
}
export const AWS = (function () {
    let instance;
    function createInstance() {
      return new AuthService();
    }
    return {
      getInstance() {
        if (!instance) {
          instance = createInstance();
        }
        return instance;
      },
    };
})();