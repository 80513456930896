import useGraphQL from "../GraphQL/GraphQL";
import {DISASSOCIATE_BY_POD_ID} from "../GraphQL/Mutations";
import {useState} from "react";
import { AWS } from '../Services/AuthService';


export const useDisassociatePod = () => { 
    const aws = AWS.getInstance();
    const client = aws.getClient();
    

    const disassociatePodByPodId = async params => {
        try {
          const response = await client.mutate({
            mutation: DISASSOCIATE_BY_POD_ID,
            variables: {groupId: params.variables.groupId, podId: params.variables.podId},
          });
          
          const { data, error } = response;
          if (!error) {
            if (data && data.disassociatePodByPodId) {
              return data.disassociatePodByPodId.message;
            }
          }
        } catch (ex) {
          console.log("disassociatePodByPodId error: ",ex);
          return {};
        }
        
      };
    return {
      disassociatePodByPodId
    }
}