import { gql, useMutation } from '@apollo/client';

export const DISASSOCIATE_BY_POD_ID = gql`
mutation disassociatePodByPodId ($groupId: String!,$podId: String!){
    disassociatePodByPodId(groupId: $groupId, podId: $podId
    ) {
            message
        }
      }
`;

