import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';
import './scanPodId.scss'
import { AWS } from '../../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import { GET_CURRENT_POD, GET_POD_INFO } from '../../GraphQL/Queries';
import { useQuery } from '@apollo/client';
import '../ScanPodId/podInfo.scss'
import moment from 'moment/moment';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import useGraphQL from '../../GraphQL/GraphQL';
import PopupMenu from '../../Components/Popup';
import {
    COMMAND_RESPONSE_UUID,
    COMMAND_UUID,
    INFORMATION_UUID,
    SKIIN_SERVICE_UUID,
    STORED_METRICS_UUID
} from "../../Utlis/constant";
import { useGetUserGroups } from '../../Hooks/useGetUserGroups';
import { useGetCurrentPod } from '../../Hooks/useGetCurrentPod';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

function PodInfo() {
    const aws = AWS.getInstance();
    const arrow = require('../../Assests/arrow_back.png');
    const groupID = localStorage.getItem('clinicId');
    const podID = localStorage.getItem("podId");
    const [clinicName, setClinicName] = useState('');
    const { userGroups, getUserGroups } = useGetUserGroups();
    const { currentPod, getCurrentPod } = useGetCurrentPod();
    const [readPodText, setReadPodText] = useState('Connect and Read Pod Data');
    const [isPodUnlocked, setPodUnlocked] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getUserGroups().then();
        setIsLoading(true)
    }, [])

    const navigate = useNavigate();
    const { makeAPIQueryWithOptions } = useGraphQL();
    const [result, setResult] = useState('');

    useEffect(() => {
        const name = localStorage.getItem("clinicName")
        setClinicName(name)
        fetchPodInfo();
    }, []);

    const fetchPodInfo = async () => {

        try {
            const response = await makeAPIQueryWithOptions('getCurrentPod', GET_CURRENT_POD, {
                groupId: groupID, podId: podID.slice(1, 12)
            });
            const result = response?.data
            if (result) {
                setIsLoading(false)
            }
            return setResult(result)
        } catch (ex) {
            console.log('getCurrentPod Summary', ex);
        }
    };


    const timestamp = localStorage.getItem("timestamp");

    // get serial number from podID which is populated from ScanPodId page
    let serialNo = podID.slice(1, 12)
    let serialNoToString = serialNo.toString()


    const connectPod = () => {
        var date = new Date(parseInt(timestamp));
        return moment(date).format('YYYY-MM-DD');
    }

    // useEffect(() => {
    //     if(userGroups.length) {
    //         console.log(userGroups);
    //         getCurrentPod({ groupId: userGroups[0].groupId, podId: '081031600203'}).then();
    //     }
    // }, [userGroups])


    useEffect(() => {
        const id = localStorage.getItem("clinicId")
        console.log('groupID: = ', groupID)

        if (id) {
            const fileteredClinic = aws.getGroups.filter(clinic => clinic.groupId === id);
            setClinicName(fileteredClinic[0]?.groupName)
        };
    }, []);

    // const { data } = useQuery(GET_CURRENT_POD, {
    //     variables: { groupId: groupID, podId: podID.slice(1, 12) }
    // });
    // console.log("data", data);
    // console.log('data?.getCurrentPod.message = ', data?.getCurrentPod.message)


    const displayTime = (timestamp) => {

        var date = new Date(parseInt(timestamp));
        return moment(date).format('YYYY-MM-DD');
    }

    const back = () => {
        navigate("/scan-pod-id")
    }

    const viewHistory = () => {
        navigate("/pod-history");
    }


    return (
        <>
            <div className="app flex-row align-items-center">
                <Container className='form-container'>
                    <Row >
                        <Col md="5" className='container-width'>
                            <div style={{ display: 'flex', marginTop: "50px", marginBottom: "30px" }}>
                                <div>
                                    <Button className='back-btn' onClick={back}>
                                        <img alt="back-arrow" className='back-arrow-img' src={arrow} />
                                        {t("back")}
                                    </Button>
                                </div>
                                <div className='clinic-div'><p className='clinic-name-text'> {clinicName} </p></div>
                                <div><Button className='logput-btn' onClick={() => { aws.logout(); }}>{t("logOut")}</Button></div>
                            </div>
                            {isLoading ? <div className='loading-div'><ClipLoader color='#022C4E' /> </div> :
                                <CardGroup>
                                    <Card className='card-container'>
                                        <CardBody className='card-body'>
                                            <div className='data-div'>
                                                <div className='pod-id-div'>{t("podID")} {podID}</div>
                                                {result?.getCurrentPod?.message === 'The pod is associated with a patient who is not a part of the clinic' ?
                                                    <div className='message-div'>{t("notLinkedWithClinicText")}</div>
                                                    :
                                                    result?.getCurrentPod?.message === 'The pod is currently not associated with a patient' ?
                                                        <div className='message-div'>{t("notLinkedWithPatientText")}</div>
                                                        :
                                                        <div>
                                                            <div className='associated-div'>{t("linkedWith")}<span className='associated-text'> {result?.getCurrentPod?.data.name}</span>
                                                            </div>
                                                            <div className='date-div'>{t("linked")} <span className='associated-text'> {displayTime(result?.getCurrentPod?.data.timestamp)}</span></div>
                                                        </div>}
                                            </div>
                                            <Popup
                                                className='pop-up-container'
                                                trigger={<Button
                                                    // onClick={onPressDisassociate}
                                                    type="button"
                                                    color="primary"
                                                    className="px-4 clear-data-btn"
                                                    disabled={(result?.getCurrentPod?.message === 'The pod is associated with a patient who is not a part of the clinic') || result?.getCurrentPod?.message === 'The pod is currently not associated with a patient' ? true : false}
                                                >
                                                    {t("unlinkPodBtnText")}
                                                </Button>}
                                                modal
                                                nested
                                            >
                                                {close => (
                                                    <PopupMenu close={(reloadPodList = false) => {
                                                        if (reloadPodList)
                                                            fetchPodInfo();
                                                        close();
                                                    }}></PopupMenu>
                                                )}

                                            </Popup>

                                            <Button
                                                onClick={viewHistory}
                                                type="button"
                                                color="primary"
                                                className="px-4 view-history-btn"
                                            >
                                               {t("viewHistoryBtnText")}
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </CardGroup>}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default PodInfo;