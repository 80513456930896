import { gql } from '@apollo/client';

export const GET_USER_GROUP = gql`
query{
    getUserGroups{
       groupId
       groupName
     }
    }
`;

export const GET_CURRENT_POD = gql`
query getCurrentPod ($groupId: String!, $podId: String!){
    getCurrentPod( groupId: $groupId, podId: $podId
    ) {
        message
        data {
            name
            timestamp
        }
    }
}
`;

export const GET_POD_INFO = gql`
query getPodInfo($podId: ID!, $secretNumber: [Int!], $IV: [Int!]) {
  getPodInfo(podId: $podId, secretNumber: $secretNumber, IV: $IV) {
    IV
    encryptionKey
    podId
    encryptedSecretNumber
    secretNumber
  }
}
`;

export const GET_POD_HISTORY = gql`
query getPodHistory ($groupId: String!, $podId: String!){
    getPodHistory( groupId: $groupId, podId: $podId
    ) {
        message
        data {
            associatedWith
            disassociatedBy
            disassociationTime
        }
    }
}
`;

export const GET_PORTAL_VERSION = gql`
  query getWebVersion {
    getWebVersion {
      timestamp
      version
    }
  }
`;

export const GET_MBE_VERSION = gql`
  query getGqlVersion {
    getGqlVersion {
      timestamp
      version
    }
  }
`;