import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Row, Input } from 'reactstrap';
import './scanPodId.scss'
import { AWS } from '../../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ScanPodId() {

    const [podId, setPodId] = useState('');
    const [clinicName, setClinicName] = useState('');

    useEffect(() => {
        const name = localStorage.getItem("clinicName")
        setClinicName(name)
    },[]);

    const aws = AWS.getInstance();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
  

const formSubmit = () => {
    navigate("/qr-code-scanner")
}

const Check = (e) => {
    localStorage.setItem("podId", podId);
    navigate("/pod-info")
}
    return (
        // eslint-disable-next-line react/jsx-no-comment-textnodes
        <div className="app flex-row align-items-center">
            <Container className='form-container'>
                <Row >
                    <Col md="5" className='container-width'>
                    <div style={{display: 'flex', marginTop: "50px"}}>
                    <div className='clinic-name-div'><p className='clinic-name'> {clinicName} </p></div>
                    <div><Button className='logput-btn' onClick={() => {aws.logout();}}>{t("logOut")}</Button></div>
                    </div>
                        <CardGroup>
                            <Card className='card-container'>
                                <CardBody className='card-body'>
                                    <div className='info-text-div'>
                                        <p className='info-text-heading'>{t("scanPodHeading")}</p>
                                            <ul>
                                                <li className='points'>{t("scanPodListItem1")}</li>
                                                <li className='points'>{t("scanPodListItem2")}</li>
                                                <li className='points'>{t("scanPodListItem3")}</li>
                                                <li className='points'>{t("scanPodListItem4")}</li>
                                            </ul>
                                    </div>
                                    <div className='option-div'>
                                        <p className='info-text'>{t("scanPodOption1")}</p>
                                        <Button
                                            onClick={formSubmit}
                                            type="button"
                                            color="primary"
                                            className="px-4 scan-qr-btn"
                                        >
                                            {t("scanQRBtnText")}
                                        </Button>
                                    </div>
                                    <div className='option-div'>
                                        <p className='info-text'>{t("scanPodOption2")}</p>
                                        <Input
                                            onChange={evt => {
                                                let val = evt.target.value;
                                                if(/^[0-9]{0,12}$/.test(val) && val.length <= 12)
                                                    setPodId(val)
                                            }}
                                            value={podId}
                                            id="podId"
                                            name="podId"
                                            className="pod-input"
                                            type="number"
                                            pattern="/^[0-9]{12}$/"
                                            maxLength={12}
                                            placeholder="Enter 12-digit Pod iD"
                                            //onInput = {(e) =>{
                                               // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)}}                                        
                                        //autoComplete="email"
                                        />
                                         <Button
                                            onClick={Check}
                                            type="button"
                                            color="primary"
                                            className={`px-4 ${ !/^[0-9]{12}$/.test(podId) ? 'disabled-check-text-btn' : 'check-text-btn'}`}
                                            disabled={( !/^[0-9]{12}$/.test(podId) ? true : false)}
                                        >
                                            {t("checkBtnText")}
                                        </Button> 
                                    </div>
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ScanPodId;