
import React, { useEffect, useState } from 'react';
import { AWS } from '../../Services/AuthService';
import ClipLoader from 'react-spinners/ClipLoader';
import './login.scss';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery } from '@apollo/client';
import  {GET_PORTAL_VERSION} from '../../GraphQL/Queries';
import { GET_MBE_VERSION } from '../../GraphQL/Queries';
import { useTranslation } from 'react-i18next';
import { MVCPWebsite } from '../../Utlis/constant'

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from 'reactstrap';
import { Navigate, useNavigate } from 'react-router';

const logo = require('../../Assests/skinnLogo.png');
const user = require('../../Assests/user.png');
const lock = require('../../Assests/password.png');

const toastConfig = {
  position: 'bottom-center',
  theme: 'colored',
  hideProgressBar: true,
};

function Login() {

  const aws = AWS.getInstance();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false)
  const [sessionTimeout, setSessionTimeout] = useState(false)
  const [invalidCreds, setInvalidCreds] = useState(false)
  const [invalidRole, setInvalidRole] = useState(false)
  const toastId = React.useRef(null);
  const { t, i18n } = useTranslation();


  useEffect(()=> {
    if(localStorage.getItem('session-timeout')) {
      setSessionTimeout(true);
      setTimeout(()=>localStorage.removeItem('session-timeout'), 1000);
    }
    if(localStorage.getItem('invalid-role')) {
      setInvalidRole(true)
      setTimeout(()=>localStorage.removeItem('invalid-role'), 1000);
    }  
  }, [])
 const useGetPortalVersion = () => {
  const {loading,error,data} = useQuery(GET_PORTAL_VERSION);
  if (loading) return 'Loading...';
  if (error) return 'Error! ${error.message}';
  return (data?.getWebVersion.version)
 }

 const useGetGQLVersion = () => {
  const {loading,error,data} = useQuery(GET_MBE_VERSION);
  if (loading)  return 'Loading...';
  if (error)  return 'Error! ${error.message}';
  return (data?.getGqlVersion.version)
 }

  const authenticate = e => {
    e.preventDefault();
    if (email.length === 0 || password.length === 0) {
      setError(true);
      return;
    }
    setIsLoading(true);
    aws
      .login(email, password)
      .then(resp => {
        if (resp.id) {
          console.log("aws.isAuthenticated: ", aws.isAuthenticated);
          aws.isAuthenticated.next(true);
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.error('Login Error:', err);
        setInvalidCreds(true);
          // toastId.current = toast.error('Invalid credentials', toastConfig);
        }
)
      .finally(() => setIsLoading(false));

    return null;
  };


  const loginForm = (
    <>
      {/* <p className="signin-text">Log in using your MVCP credentials.</p> */}
      <p className='signin-text'>{t("loginText")}</p>
      <Form className="form-horizontal" onSubmit={authenticate}>
        <InputGroup className="input-field">
          <InputGroupText className="input-icon">
            <img alt="user" className='icon-img' src={user} />
          </InputGroupText>
          <Input
            className="field-input"
            type="text"
            placeholder="Email"
            autoComplete="email"
            onChange={evt => {
              setInvalidCreds(false)
              setInvalidRole(false)
              setEmail(evt.target.value)
            }}
          />
        </InputGroup>
        <div>
          {error && email.length <= 0 ?
            <label className='error-message'>{t("emailErrorText")}</label> : ""}
        </div>
        <InputGroup className="input-field">
          <InputGroupText className='input-icon'>
            <img alt="password" className='icon-img' src={lock} />
          </InputGroupText>
          <Input
            className='field-input'
            type="password"
            placeholder="Password"
            autoComplete="current-password"
            onChange={evt => {
              setInvalidCreds(false)
              setInvalidRole(false)
              setPassword(evt.target.value)
            }}
          />
        </InputGroup>
        {error && password.length <= 0 ?
          <label className='error-message'>{t("passwordErrorText")}</label> :
        (invalidCreds || invalidRole)?
          <label className='error-message'>{t("invalidCredErrorText")}</label> : ""}
        <Row>
          <Col xs="6" className='login-btn'>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              className="px-4 login-text"
            >
              {isLoading && <ClipLoader color="#fff" loading size={15} />}
              {!isLoading && t("loginBtnText")}
            </Button>
            {sessionTimeout && <label className='logout-error-message'>{t("inactivityLogOutText")}</label>}
          </Col>
        </Row>
      </Form>
    </>
  );

  return (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
     <div className="app flex-row align-items-center">
        <Container className='form-container'>
          <Row className="container-row">
            <Col md="5" className='container-width'>
              <CardGroup>
                <Card className="container-card">
                  <Row className='img-row'>
                    <img alt="SKIIN" className="skinn-img" src={logo} />
                  </Row>
                  <p className='logo-text'>{t("PAT")}</p>
                  <CardBody className='form'>
                    {loginForm}
                  </CardBody>
                </Card>
              </CardGroup>
              <p className='bottom-text' style={{ marginTop: '70px' }}>{t("forgetPasswordText1")} <a className='website-link' href={MVCPWebsite}>{t("MVCP")}</a>{t("forgetPasswordText2")}</p>
              <p className='bottom-text'>{t("forgetPasswordText3")}</p>
              <p className='bottom-text' style={{ marginTop: '60px' }}>{t("skiinPATVer")} {useGetPortalVersion()}</p>
              <p className='bottom-text' style={{ marginTop: '-20px' }}>{t("skiinAPIVer")}{useGetGQLVersion()}</p>
            </Col>
          </Row>
        </Container>
      </div>
  );
}

export default Login;