import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './Pages/Login/login';
import SelectClinic from './Pages/SelectClinic/selectClinic';
import { Amplify } from 'aws-amplify';
import { browserName, browserVersion, config, OSName } from './Utlis/constant'
import { AWS } from './Services/AuthService';
import { ApolloProvider } from '@apollo/client';
import { GridLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';
import CapturePodId from './Pages/CapturePodId/capturePodId';
import ScanPodId from './Pages/ScanPodId/scanPodId';
import QrContainer from './Pages/ScanPodId/QRCode';
import PodInfo from './Pages/ScanPodId/podInfo';
import ViewPodHistory from './Pages/PodHistory/viewPodHistory';
import {
  Button, Col
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

Amplify.configure({
  ...config.awsconfig,
  Analytics: {
    disabled: true,
  },
});

const loading = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100vh',
    }}
  >
    <GridLoader color="#033761" loading size={13} margin={2} />
  </div>
);

function App() {
  const aws = AWS.getInstance();
  const [isAuthenticated, setisAuthenticated] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const isClose = localStorage.getItem("close");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    aws.checkUserAuthenticated();
    const sub = aws.isAuthenticated.subscribe(result => {
      if (typeof result === 'boolean') {
        console.log("User logged", result)
        setIsLoading(false);
        setisAuthenticated(result);
        // this.setState({ isAuthenticated: result, isLoading: false });      
      }
    });
    return () => {
      sub.unsubscribe();
    }
  }, []);

  function getBrowserInfo() {
    const userAgent = window.navigator.userAgent;
    let name = 'Unknown';
    let version = '';
  if (userAgent.indexOf('Firefox') !== -1) {
    name = 'Firefox';
    version = userAgent.split('Firefox/')[1];
  } else if (userAgent.indexOf('Chrome') !== -1) {
    name = 'Chrome';
    version = userAgent.split('Chrome/')[1].split(' ')[0];
  } else if (userAgent.indexOf('Safari') !== -1) {
    name = 'Safari';
    version = userAgent.split('Version/')[1].split(' ')[0];
  } else if (userAgent.indexOf('Edge') !== -1) {
    name = 'Edge';
    version = userAgent.split('Edge/')[1];
  } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident') !== -1) {
    name = 'Internet Explorer';
    version = userAgent.indexOf('MSIE') !== -1
      ? userAgent.split('MSIE ')[1].split(';')[0]
      : userAgent.split('rv:')[1].split(')')[0];
  }

  return {
    name,
    version,
  };
}

function detectOS() {
  const platform = navigator.platform;
  if (platform.indexOf('Win') !== -1) return 'Windows';
  if (platform.indexOf('Mac') !== -1) return 'Mac OS';
  if (platform.indexOf('Linux') !== -1) return 'Linux';
  if (platform.indexOf('iPhone') !== -1) return 'iOS';
  if (platform.indexOf('Android') !== -1) return 'Android';
  if (platform.indexOf('iPad') !== -1) return 'iPad';
  return 'Unknown';
}

useEffect(() => {
  if(!(getBrowserInfo().name === browserName && browserVersion.includes(getBrowserInfo().version) && detectOS() === OSName)){
    if(!isClose)
      setIsOpen(true)
  }
}, [])

function toggle() {
setIsOpen((isOpen) => !isOpen);
localStorage.setItem("close", true)
}

function Banner(){
  return(
    <div className='banner-div'> 
     {isOpen &&
     <div className='banner-content'> {t("bannerContent")}
      <div input-type= "button" className='close-btn' onClick={toggle}>{t("close")}</div>
     </div>}
      </div>
  )
}


  if (isLoading) return loading();

  return (
    <div className="App">
      <ApolloProvider client={aws.getClient()}>
        <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <ToastContainer />
            <Banner></Banner>
            {!isAuthenticated ? (
              <Routes>
                <Route exact path="/" element={<Login />}/>
                </Routes>
            ) : (
              <Routes>
                  <Route exact path="/" element={<SelectClinic />} />
                  {/* <Route exact path="/capture-pod-id" element={<CapturePodId />} /> */}
                  <Route exact path="/scan-pod-id" element={<ScanPodId />} />
                  <Route exact path="/qr-code-scanner" element={<QrContainer />} />
                  <Route exact path="/pod-info" element={<PodInfo />} />
                  <Route exact path="/pod-history" element={<ViewPodHistory />} />
                </Routes>
            )}
          </React.Suspense>
        </BrowserRouter>
      </ApolloProvider>
    </div>
  );
}

export default App;
