import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Utlis/en'
import App from './App';
 import 'bootstrap/dist/css/bootstrap.min.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
//ReactDOM.render(<App/>,document.getElementById("root"))
root.render(

    <App />
);