import { AWS } from '../Services/AuthService';

const useGraphQL = () => {
    const aws = AWS.getInstance();
    const client = aws.getClient();

    const makeAPIQueryWithOptions = async (functionName, query, variables, options = {fetchPolicy: 'no-cache'}) => {
        let returnObject = {result: null, error: null};
        const params = variables
            ? {
                query,
                variables,
                ...options,
            }
            : {
                query,
                ...options,
            };

        try {
          aws.isTimeout();
            returnObject = await client.query(params);
        } catch (err) {
            console.error('Error => ', functionName, err);
            returnObject.error = err;
        }
        return returnObject;
    };

    const makeAPIMutationWithOptions = async (functionName, mutation, variables, options = {fetchPolicy: 'no-cache'}) => {
      let returnObject = {result: null, error: null};
      const params = variables
          ? {
            mutation,
              variables,
              ...options,
          }
          : {
            mutation,
              ...options,
          };

      try {
        aws.isTimeout();
          returnObject = await client.mutate(params);
      } catch (err) {
          console.error('Error => ', functionName, err);
          returnObject.error = err;
      }
      return returnObject;
  };
    return {
        makeAPIQueryWithOptions,
        makeAPIMutationWithOptions
    };



};

export default useGraphQL;
