import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Row, Input } from 'reactstrap';
import { AWS } from '../../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import { GET_POD_HISTORY } from '../../GraphQL/Queries';
import { useQuery } from '@apollo/client';
import '../PodHistory/viewPodHistory.scss'
import moment from 'moment/moment';
import useGraphQL from '../../GraphQL/GraphQL';
import { useTranslation } from 'react-i18next';

function ViewPodHistory() {
    const aws = AWS.getInstance();
    const arrow = require('../../Assests/arrow_back.png');
    const groupID = localStorage.getItem('clinicId');
    const podID = localStorage.getItem("podId");
    const [clinicName, setClinicName] = useState('');
    const navigate = useNavigate();
    const { makeAPIQueryWithOptions } = useGraphQL();
    const [result, setResult] = useState('');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const name = localStorage.getItem("clinicName")
        setClinicName(name)
        fetchPodHistory()
    }, []);

    const fetchPodHistory = async () => {
       
        try {
            const response = await makeAPIQueryWithOptions('getPodHistory', GET_POD_HISTORY, {
                groupId: groupID, podId: podID.slice(1, 12)
              });
              const result = response?.data
              setResult(result)
        } catch (ex) {
          console.log('getPodHistory Summary', ex);
        }
      };


    const displayTime = (timestamp) => {

        var date = new Date(parseInt(timestamp));
        return moment(date).format('YYYY-MM-DD');;
    }


    const back = () => {
        navigate("/pod-info")
    }


    return (
        <>
            <div className="app flex-row align-items-center">
                <Container className='form-container'>
                    <Row >
                        <Col md="5" className='container-width'>
                            <div style={{ display: 'flex', marginTop: "50px", marginBottom: "30px" }}>
                                <div>
                                    <Button className='back-btn' onClick={back}>
                                    <img alt="back-arrow" className='back-arrow-img' src={arrow} />
                                        {t("back")}
                                        </Button>
                                        </div>
                                <div className='clinic-div'><p className='clinic-name-text'> {clinicName} </p></div>
                                <div><Button className='logput-btn' onClick={() => {aws.logout()}}>{t("logOut")}</Button></div>
                            </div>
                            <CardGroup>
                                <Card className='card-container'>
                                    <CardBody className='card-body'>
                                        <div className='pod-id-div'>{t("podID")} {podID} {t("historyTitle")}</div>
                                        {result?.getPodHistory?.data?.length === 0 ?
                                            <div className='no-history-text'>
                                                {t("noHistoryText")}
                                            </div>
                                            :
                                            <div>
                                                {result?.getPodHistory?.data?.map(gethistory => (
                                                    <div className="history-data-group-div">
                                                        <div className='history-data-div'>{t("unlinked")} <span className='history-data-text'> {displayTime(gethistory.disassociationTime)} </span> </div>
                                                        <div className='history-data-div'>{t("linkedWith")} <span className='history-data-text'> {gethistory.associatedWith} </span></div>
                                                        <div className='history-data-div'>{t("unlikedBy")} <span className='history-data-text'> {gethistory.disassociatedBy === null ? "Patient" : gethistory.disassociatedBy} </span></div>
                                                    </div>

                                                    //   <div>
                                                    //     Assocd with:
                                                    //   </div>
                                                    //   <div>
                                                    //     Disassocd by:
                                                    //   </div>
                                                ))}</div>}
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ViewPodHistory;